import { Hidden, Step, StepLabel, Stepper } from "@mui/material";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { commonApiCall, showErrorToast } from "../service/api";
import PayPalPaymentGateway from "../service/common";
import { payment } from "../service/payment";
import PaymentResponse from "../service/payment-response";
import ConfirmPurchase from "./purchases/confirm-purchase";
import TicketSelection from "./purchases/ticket-selection";
import VisitorInformation from "./purchases/visitor-information";
const steps = ["Ticket Selection", "Visitors Information", "Confirm Purchase"];

const TicketBooking = () => {
  const navigate = useNavigate();
  const __DEV__ = document.domain === "localhost";
  const dateTimeRef = React.useRef(null);
  const urlPlace = new URLSearchParams(window.location.search).get("place");
  const [activeStep, setActiveStep] = React.useState(1);
  const [showConfirm, setShowConfirm] = useState(false);
  const [visitorsError, setVisitorError] = useState({});
  const [loading, setLoading] = useState(false);
  const [agree, setAgree] = useState(false);
  const [paymentDetail, setPaymentDetail] = useState(null);
  const [paymentJson, setPaymentJson] = useState(null);
  const [ticketError, setTicketError] = useState({});
  const [tranId, setTranId] = useState(0);
  const [currentIP, setCurrentIp] = useState("");
  const [currentIPCountry, setCurrentIpCountry] = useState("");
  const [showPaymentMethod, setShowPaymentMethod] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("paypal");

  const [ticketForm, setTicketForm] = useState({
    price: "",
    date: "",
    days: "",
    note1: null,
    validity: 0,
    ticket_id: null,
  });

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    code: "",
    mobile: "",
  });

  const [visitorList, setVisitorList] = useState([
    {
      image: null,
      gender: "",
      age: "",
      nationality: "",
    },
  ]);

  const togglePaymentMethod = () => setShowPaymentMethod(!showPaymentMethod);

  const handleFormDataChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const onTicketChange = (name, value) => {
    if (name === "price") {
      setTicketForm({
        ...ticketForm,
        price: value.price,
        days: value.days,
        note1: value.note1,
        validity: value.validity,
        ticket_id: value.tran_id,
      });
    } else {
      setTicketForm({ ...ticketForm, [name]: value });
    }
  };

  const getIPAddress = async () => {
    try {
      axios({
        method: "get",
        url: `https://ipapi.co/json`,
      }).then(function (response) {
        setCurrentIp(response.data.ip);
        setCurrentIpCountry(response.data.country_name);
      });
    } catch (err) {
      alert(err);
    }
  };

  useEffect(() => {
    getIPAddress();
    const secondsTimer = setInterval(() => {
      if (dateTimeRef.current) {
        dateTimeRef.current.innerText =
          "Cambodia Time: " +
          moment().add(90, "m").format("MMMM Do YYYY, h:mm:ss a");
      }
    }, 1000);
    return () => clearInterval(secondsTimer);
  }, []);

  const validateVisitors = () => {
    let visitorError = {};
    let keys = {};

    visitorList.forEach((x, index) => {
      if (
        urlPlace &&
        decodeURIComponent(urlPlace).trim().toLowerCase() !== "kohker temple" &&
        x.image === null
      ) {
        keys.image = "image required";
      }
      if (x.gender === "") {
        keys.gender = "this field is required";
      }

      if (x.nationality === "") {
        keys.nationality = "this field is required";
      }

      if (x.age === "") {
        keys.age = "this field is required";
      }
      if (Object.keys(keys).length > 0) {
        visitorError[index] = keys;
      }
    });

    setVisitorError(visitorError);
    return Object.keys(visitorError).length === 0;
  };

  const validateTicket = () => {
    let error = {};

    if (ticketForm.price === "") {
      error.price = "Please choose day pass";
    } else {
      delete error.price;
    }
    if (ticketForm.date === "") {
      error.date = "Please choose visiting date";
    } else if (
      ticketForm.date.getDate() === new Date().getDate() &&
      new Date().getHours() > 15
    ) {
      error.date = "You can't purchase ticket for today";
    } else {
      delete error.date;
    }

    setTicketError(error);
    return Object.keys(error).length === 0;
  };

  const toggleNextStep = () => {
    if (activeStep === 1 && validateTicket()) {
      setActiveStep(activeStep + 1);
    } else if (activeStep === 2 && validateVisitors()) {
      setActiveStep(activeStep + 1);
    } else if (activeStep === 3) {
      onCheckoutInsert(false);
    } else {
      showErrorToast(
        ticketForm.date == ""
          ? "Please choose visiting date"
          : "Please choose your ticket"
      );
    }
  };

  const onListChange = (data) => {
    setVisitorList(data);
  };

  const addDays = (date, days) => {
    let result = new Date(date);
    if (days === 1) {
      return result;
    } else {
      result.setDate(result.getDate() + days - 1);
      return result;
    }
  };

  const doPayment = () => {
    setLoading(true);
    if (selectedPaymentMethod === "razorpay") {
      payment.displayRazorpay(paymentJson, (data) => {
        setPaymentDetail(data);
        setShowConfirm(false);

        setLoading(false);
        navigate("/ticket/response", {
          state: { data, paymentJson },
        });
      });
    } else {
    }
    setLoading(false);
  };

  const onCheckoutInsert = async (user = false) => {
    setLoading(true);
    try {
      let obj = {
        tran_id: tranId,
        first_name: formData.firstName,
        last_name: formData.lastName,
        gender: "",
        email: formData.email,
        country_code: formData.code,
        mobile: formData.mobile,
        date_of_entry: moment(ticketForm.date).format("YYYY-MM-DD"),
        date_of_expiry: moment(
          addDays(ticketForm.date, ticketForm.validity)
        ).format("YYYY-MM-DD"),
        currency: "USD",
        rate: ticketForm.price,
        amount: ticketForm.price * visitorList.length,
        ip_address: currentIP,
        ip_address_country: currentIPCountry,
        browser: "",
        ag_application_visitors: visitorList,
        website_url: window.location.href,
        ticket_id: ticketForm.ticket_id,
      };

      let response = await commonApiCall("api/main", "post", obj);
      if (response.status === 200) {
        if (user) {
          setTranId(response.data);
        } else {
          let paymentobj = response.result;
          paymentobj.receipt = response.data;
          paymentobj.amount = paymentobj.amount * 100;
          paymentobj.name = formData.firstName + " " + formData.lastName;
          paymentobj.visitors = visitorList.length;
          console.log(paymentobj);
          setPaymentJson(paymentobj);
          setShowConfirm(true);
        }
        setLoading(false);
      }
    } catch (err) {
      alert(err);
      setLoading(false);
    }
  };

  const toggleBackstep = () => setActiveStep(activeStep - 1);
  return (
    <div className="ticket-booking-main px-md-5 py-3">
      <div className="d-flex align-items-center justify-content-between">
        <button
          className="btn btn-link text-decoration-none p-0 text-dark"
          onClick={() => navigate("/ticket")}
        >
          <i className="fa-solid fa-arrow-left-long me-2"></i>
          <span className="d-none d-md-inline-block"> Cancel</span>
        </button>
        <p className="mb-0" ref={dateTimeRef} />
      </div>
      <div className="stepper-form mt-5">
        <div className="container">
          <Stepper activeStep={activeStep}>
            {steps.map((label) => {
              return (
                <Step key={label}>
                  <StepLabel>
                    <Hidden xsUp> {label}</Hidden>
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === 1 && (
            <TicketSelection
              data={ticketForm}
              error={ticketError}
              onTicketChange={onTicketChange}
            />
          )}
          {activeStep === 2 && (
            <VisitorInformation
              list={visitorList}
              onListChange={onListChange}
              formData={formData}
              onFormDataChange={handleFormDataChange}
              visitorError={visitorsError}
              loading={loading}
              onCancel={() => setActiveStep(1)}
              saveUser={() => onCheckoutInsert(true)}
            />
          )}
          {activeStep === 3 && (
            <ConfirmPurchase
              ticketData={ticketForm}
              visitorList={visitorList}
            />
          )}
          {activeStep === 4 && (
            <PaymentResponse
              details={paymentDetail}
              updatePayment={setPaymentDetail}
            />
          )}
          <div className="d-flex justify-content-between">
            {activeStep !== 1 && (
              <button className="btn btn-primary " onClick={toggleBackstep}>
                Back <i class="fa-solid fa-arrow-left"></i>
              </button>
            )}
            <button
              className="btn btn-primary "
              disabled={loading}
              onClick={toggleNextStep}
            >
              {loading ? "Processing..." : "Next"}{" "}
              <i class="fa-solid fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>

      <Modal
        size="lg"
        onHide={() => setShowConfirm(false)}
        centered
        show={showConfirm}
      >
        <Modal.Header closeButton>
          <Modal.Title>Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!showPaymentMethod ? (
            <React.Fragment>
              <Table striped="columns" responsive>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Pass Type</th>
                    <th>Ticket Type</th>
                    <th>Quantity</th>
                    {/* <th>Rate</th> */}
                    {/* <th>Total Price</th> */}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>{decodeURIComponent(urlPlace)}</td>
                    <td>{ticketForm.days} Day Pass</td>
                    <td>{visitorList.length}</td>
                    {/* <td>${ticketForm.price}.00</td> */}
                    {/* <td>${visitorList.length * ticketForm.price}.00</td> */}
                  </tr>
                </tbody>
              </Table>
              <div className="d-flex justify-content-between">
                <p className="text-danger">
                  Note: The amount you have paid successfully will not be
                  refunded.
                </p>
                {!loading && (
                  <Form.Check
                    type={"checkbox"}
                    id={`default-checkbox`}
                    label={`I agree to the Terms and Conditions`}
                    checked={agree}
                    onChange={(e) => setAgree(e.target.checked)}
                  />
                )}
              </div>
            </React.Fragment>
          ) : (
            <div className="payment-methods d-md-flex align-items-center justify-content-center">
              <label htmlFor="razorpay-radio">
                <div
                  className={
                    "payment-icon " +
                    (selectedPaymentMethod === "razorpay" ? "active" : "")
                  }
                >
                  <img src="/images/razorpay.svg" className="img-fluid" />
                </div>
                <input
                  type="radio"
                  id="razorpay-radio"
                  style={{ display: "none" }}
                  name="payment"
                  value={"razorpay"}
                  onChange={(e) => setSelectedPaymentMethod(e.target.value)}
                />
              </label>
              <span className="d-inline-block mx-3"> or</span>
              <label htmlFor="paypal-radio">
                <div
                  className={
                    "payment-icon " +
                    (selectedPaymentMethod === "paypal" ? "active" : "")
                  }
                >
                  <img src="/images/paypal.png" className="img-fluid" />
                </div>
                <input
                  type="radio"
                  id="paypal-radio"
                  style={{ display: "none" }}
                  name="payment"
                  value={"paypal"}
                  onChange={(e) => setSelectedPaymentMethod(e.target.value)}
                />
              </label>
            </div>
          )}
          {showPaymentMethod && selectedPaymentMethod === "paypal" && (
            <div className="w-100 mt-3">
              <PayPalPaymentGateway
                paymentJson={paymentJson}
                callback={(param) => {
                  setPaymentDetail(param);
                  setShowConfirm(false);

                  setLoading(false);
                  navigate("/ticket/response", {
                    state: { data: param, paymentJson },
                  });
                }}
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {/* <Button
            variant="danger"
            onClick={() =>
              showPaymentMethod ? togglePaymentMethod() : setShowConfirm(false)
            }
          >
            {showPaymentMethod ? "Back" : "Close"}
          </Button> */}

          {showPaymentMethod ? (
            selectedPaymentMethod !== "paypal" && (
              <Button
                variant="primary"
                disabled={loading || !agree}
                onClick={() => doPayment()}
              >
                {loading ? "Please wait, it will take few seconds" : "Checkout"}
              </Button>
            )
          ) : (
            <Button
              variant="primary"
              disabled={!agree}
              onClick={togglePaymentMethod}
            >
              Proceed & Pay
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TicketBooking;
