import "./App.css";
import Header from "./layout/header";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import Home from "./screens/home";
import Footer from "./layout/footer";
import Banner from "./layout/banner";
import BakhengTemple from "./screens/temple/bakhengTemple";
import TicketMainLayout from "./layout/ticketMain";
import PurchasePass from "./screens/purchasePass";
import TicketBooking from "./screens/ticket-booking";
import ConfirmPurchase from "./screens/purchases/confirm-purchase";
import AllTemple from "./screens/temple/allTemple";
import React from "react";
import TempleMenu from "./layout/templeMenu";
import BanteaySritemple from "./screens/temple/banteaySritemple";
import BaphuonTemple from "./screens/temple/baphuonTemple";
import BayonTemple from "./screens/temple/bayonTemple";
import PreahKhanTemple from "./screens/temple/preahKhanTemple";
import WatTemple from "./screens/temple/watTemple";
import TempleData from "./screens/temple/temple-data";
import TermAndCondition from "./screens/termAndCondition";
import Login from "./admin/login/login";
import Otp from "./admin/login/otp";
import Adduser from "./admin/user/Adduser";
import Users from "./admin/user/userGrid";
import Admin from "./admin/admin";
import PaymentResponse from "./service/payment-response";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import RefundAndCancellation from "./screens/RefundAndCancellation";
import TicketPrices from "./screens/TicketPrices";
import ContactUs from "./screens/ContactUs";

function App() {
  const ticket = window.location.pathname.split("/");

  return (
    <Router>
      {ticket.indexOf("client-portal") < 0 && <Header />}

      {/* <Adduser/> */}
      {ticket.indexOf("ticket") < 0 && ticket.indexOf("client-portal") < 0 && (
        <Banner />
      )}
      <Routes>
        {/* <Route path="admin/login" element={<Admin />}>
          <Route index element={<Login />} />
          <Route path="otp" element={<Otp />} />
          <Route path="user" element={<Users />} />
        </Route> */}





        <Route path={"home"} element={<Home />} />
        <Route path="temples" element={<AllTemple />} />
        <Route path="client-portal" element={<Admin />}>
          <Route path="user-list" element={<Users />} />

          <Route path="login" element={<Login />} />
          {localStorage.getItem("adminAuth") ? (
            <React.Fragment>
              {" "}
              <Route index element={<Users />} />
              <Route path="preview" element={<Adduser />} />
            </React.Fragment>
          ) : (
            <Route index element={<Login />} />
          )}
        </Route>

        <Route path={"ticket"} element={<TicketMainLayout />}>
          <Route index element={<PurchasePass />} />
          <Route path="purchase" element={<PurchasePass />} />
          <Route path="booking" element={<TicketBooking />} />
          <Route path="response" element={<PaymentResponse />} />
        </Route>
        <Route path="tnc" element={<TermAndCondition />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="refund-and-cancellation" element={<RefundAndCancellation />} />
        <Route path="ticket-prices" element={<TicketPrices />} />
        <Route path="contact-us" element={<ContactUs />} />
        
        <Route path="temples" element={<AllTemple />}>
          <Route path="bakhengtemple" element={<BakhengTemple />} />
          <Route path="banteaysritemple" element={<BanteaySritemple />} />
          <Route path="baphuontemple" element={<BaphuonTemple />} />
          <Route path="bayontemple" element={<BayonTemple />} />
          <Route path="preahkhantemple" element={<PreahKhanTemple />} />
          <Route path="wattemple" element={<WatTemple />} />

          <Route index element={<TempleData />} />
        </Route>
        

        <Route index element={<Home />} />
      </Routes>
      {ticket.indexOf("ticket") < 0 && ticket.indexOf("client-portal") < 0 && (
        <Footer />
      )}
      {/* <BakhengTemple/> */}
    </Router>
  );
}

export default App;
