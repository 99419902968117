import React, { useState } from "react";
import { NavLink } from "react-router-dom";
const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const toggleMenu = () => setShowMenu(!showMenu);
  const ticket = window.location.pathname.split("/");

  return (
    <header class="headercol ">
      <div class="centerCol">
        <a href="/" class="logo">
          <img src="/images/logo.png" />
        </a>
        {/* <div class="rightside">
          <div class="email ">
            <a href="/">info@angkorenterprise.gov.kh</a>
          </div>
        </div> */}
      </div>
      {ticket.indexOf("ticket") < 0 && (
        <div class="naviBox">
          <div class="centerCol">
            <div class="menuhum" onClick={toggleMenu}>
              <div class="icon-left"></div>
              <div class="icon-right"></div>
            </div>
            {(window.innerWidth < 500 ? showMenu : true) && (
              <div class="menu">
                <ul>
                  <li>
                    <NavLink to="/home">Home</NavLink>
                  </li>
                  {/* <li>
                  <a href="/">Plan Your Visit</a>
                </li> */}
                  <li>
                    <NavLink to="/temples">Temples</NavLink>
                  </li>
                  {/* <li>
                  <a href="/">News</a>
                </li> */}
                  <li>
                    <NavLink to="/">About Us</NavLink>
                  </li>
                  <li>
                    <NavLink to="/contact-us">Contact Us</NavLink>
                  </li>
                  <li>
                    <NavLink to="/tnc">Terms & Conditions</NavLink>
                  </li>
                  <li>
                    <NavLink to="/privacy-policy">Privacy Policy</NavLink>
                  </li>
                  <li>
                    <NavLink to="/refund-and-cancellation">Refund & Cancellation</NavLink>
                  </li>
                  <li>
                    <NavLink to="/ticket-prices">Ticket Prices</NavLink>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
