import moment from "moment";
import React, { useRef, useState } from "react";
import { Modal, Table } from "react-bootstrap";
import { BASE_URL, LOCAL_BASE_URL } from "../../service/config";

const ConfirmPurchase = ({ ticketData, visitorList, ...props }) => {
  const ticketType = new URLSearchParams(window.location.search).get("place");

  const __DEV__ = document.domain === "localhost";

  const addDays = (date, days) => {
    let result = new Date(date);
    if (days === 1) {
      return result;
    } else {
      result.setDate(result.getDate() + days);
      return result;
    }
  };

  return (
    <div className="confirm-purchase mt-4 px-md-3">
      <p className="mb-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="0 0 24 24"
          fit=""
          preserveAspectRatio="xMidYMid meet"
          focusable="false"
          style={{ width: 24 }}
        >
          <path
            d="M14.25,0H2.75A2.748,2.748,0,0,0,0,2.75v15.5A2.748,2.748,0,0,0,2.75,21h8.14A7.138,7.138,0,0,1,9.5,16.75,7.255,7.255,0,0,1,16.75,9.5a2.09,2.09,0,0,1,.25.01V2.75A2.748,2.748,0,0,0,14.25,0ZM4,4H8A1,1,0,0,1,8,6H4A1,1,0,0,1,4,4ZM9,14H4a1,1,0,0,1,0-2H9a1,1,0,0,1,0,2Zm4-4H4A1,1,0,0,1,4,8h9a1,1,0,0,1,0,2Z"
            fill="#1b8e78"
          ></path>
          <path
            d="M16.75,22A5.25,5.25,0,1,1,22,16.75,5.256,5.256,0,0,1,16.75,22Zm0-8.5A3.25,3.25,0,1,0,20,16.75,3.254,3.254,0,0,0,16.75,13.5Z"
            fill="#e5b000"
          ></path>
          <path
            d="M23,24a1,1,0,0,1-.707-.293l-3.25-3.25a1,1,0,0,1,1.414-1.414l3.25,3.25A1,1,0,0,1,23,24Z"
            fill="#e5b000"
          ></path>
        </svg>
        Confirm Purchase
      </p>
      <div className="confirm-details mb-4">
        <table className=" ms-3">
          <tr>
            <th>Pass: </th>
            <td>
              &nbsp;&nbsp;
              {decodeURIComponent(ticketType)}{" "}
            </td>
          </tr>
          <tr>
            <th>Ticket Type: </th>
            <td>
              &nbsp;&nbsp;
              {ticketData.note1}
            </td>
          </tr>
          <tr>
            <th>Quantity: </th>
            <td> &nbsp;&nbsp;{visitorList.length}</td>
          </tr>
          {/* <tr>
            <th>Total Price: </th>
            <td> &nbsp;&nbsp;${ticketData.price * visitorList.length}.00</td>
          </tr> */}
          <tr>
            <th> Date of Entry: </th>
            <td>&nbsp;&nbsp;{moment(ticketData.date).format("DD/MM/YYYY")}</td>
          </tr>
          <tr>
            <th> Date of Expiry: </th>
            <td>
              &nbsp;&nbsp;
              {moment(addDays(ticketData.date, ticketData.validity)).format(
                "DD/MM/YYYY"
              )}
            </td>
          </tr>
        </table>
      </div>
      <p className="theme-text-color">
        <i className="fa-solid fa-user-group me-2"></i>
        Visitor Information
      </p>
      <div className="mb-5">
        <Table striped="columns" responsive>
          <thead>
            <tr>
              <th>No</th>
              {ticketType &&
                decodeURIComponent(ticketType).trim().toLowerCase() !==
                  "kohker temple" && <th>Photo</th>}

              <th>Gender</th>
              <th>Group Age</th>
              <th>Nationality</th>
            </tr>
          </thead>
          <tbody>
            {visitorList.map((person, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  {ticketType &&
                    decodeURIComponent(ticketType).trim().toLowerCase() !==
                      "kohker temple" && (
                      <td>
                        <img
                          style={{ width: 30, height: 30, objectFit: "cover" }}
                          src={
                            (__DEV__ ? LOCAL_BASE_URL : BASE_URL) +
                            "images/" +
                            person.image
                          }
                        />
                      </td>
                    )}

                  <td style={{ textTransform: "capitalize" }}>
                    {person.gender}
                  </td>
                  <td style={{ textTransform: "capitalize" }}>{person.age}</td>
                  <td style={{ textTransform: "capitalize" }}>
                    {person.nationality}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default ConfirmPurchase;
