import React from "react";
import image from "../../assets/images/PreahKhan-Temple.jpg";
const PreahKhanTemple = () => {
  return (
    <>
      <div className="centerCol">
        {/* / welcome box \ */}
        <div className="contentBox">
          <h3>Preah Khan Temple Ticket</h3>
          <div className="contant">
            <h4>Preah Khan Temple</h4>
            <p align="justify" className="floatL">
              <span className="floatR marLeft20">
                <img src={image} width={600} />
              </span>
              The most historical site present in This temple was built, in
              Angkor Cambodia for king Jayavarman VII, in the 12th century to
              honor his father. It had almost 100000 officials and servants and
              was considered the center of a substantial organization. Northeast
              of the Angkor Thom and west of the Jayatatakabaray are the
              locations where Preah Khan is located. When we talk about its
              architectural designit seems flat in design and has basic
              rectangular galleries which are around the Buddhist sanctuary. But
              these galleries are intricated by the Hindu satellite temples and
              many more additions. Preah khan temple has deteriorated with time
              and has been left unrestored.There are a lot of weeds, and useless
              vegetation and trees have grown in the ruins. This situation is
              the same exactly like Ta Prohm which is nearby to the temple Preah
              Khan.
              <br />
              <br />
              In the year 1191, it was built on the site of Jayavarman VII’s
              victory over invading Chams. But previously it was occupied by the
              royal palaces of Yasovarman II and Tribhuvanadityavarman. The
              modern name which means “HOLY SWORD” is derived from the meaning
              of the original – Nagara Jayasri (holy city of victory).
              <br />
              <br />
              A lot of considerable information regarding the history and
              administration of the temple can be gained from the foundation
              stela. There is the main image of the bodhisattva Avalokitesvarain
              in the form of the King’s father and it was dedicated in 1191. 430
              other deities have shrines on the same site. Each receives
              clothing, food, perfume, and mosquito nets too. They also receive
              the temple’s gold, silver, and pearls and a cow with gilded horns.{" "}
              <br />
              The institution combined the roles of city, temple, and Buddhist
              university: there were 97,840 attendants and servants, including
              1000 dancers and 1000 teachers.
            </p>
          </div>
        </div>
        {/* / welcome box \ */}
      </div>
    </>
  );
};

export default PreahKhanTemple;
