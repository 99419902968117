import React from "react";
import image from "../../assets/images/baphuon-temple-angkor-thom.jpg";
const BaphuonTemple = () => {
  return (
    <>
      <div className="centerCol">
        {/* / welcome box \ */}
        <div className="contentBox">
          <h3>Baphuon Temple Ticket</h3>
          <div className="contant">
            <h4>Baphuon Temple</h4>
            <p align="justify" className="floatL">
              <span className="floatR marLeft20">
                <img src={image} width={600} />
              </span>
              The most historical site present in Located in Angkor Thom,
              northwest of the Bayon is the Baphuon temple. It is a three-tier
              mountain temple built in the mid of 11th century as a state temple
              of Udayadityavarman II dedicated to the Hindu God Shiva. It is a
              typical example of the Baphuon style. The look of this temple
              impressed the Emperor Chengzong of Yuan, china’s 13th-century
              envoy Chou Ta- Kuan during his visit from 1296 to 1297. Who
              appreciated the temple’s beauty by saying that “it was a tower of
              bronze, a truly astonishing spectacle, with more than 10 chambers
              at its base”. The Baphuon templemeasures around 120 meters
              east-west by 100 meters north-south at its base and stands 34
              meters tall without its tower, as it adjoins the southern
              enclosure of the royal palace. Along with the tower, its total
              height would be 50 meters. <br />
              <br />
              Then in the 15th century, it was converted into a Buddhist temple
              from a Hindu temple. It has a buddha statue which is 9 meters tall
              and 70 meters long, on the west side of the second level, this
              required the deconstruction of an 8-meter tower above.
              <br />
              The temple has been unstable throughout its history as it was
              built on land filled with sand and immense size of the site. Many
              of the large portions have already flaked out by the time Buddha
              was added. It was reconstructed using pen and watercolor to make
              it look like just in the 11th century by Lucien Fournereau in
              1889.On the west side of the temple, there is an unfinished
              reclining Buddha. <br />
              It collapsed largely near the 20th century and reconstructing it,
              again and again, has been a problem since then. Reconstructing for
              the first time was in 1960 which was interrupted as Khmer Rouge
              gained power and the stone records were lost. Then the second try
              was attempted in the year 1995 which continued till 2005 by the
              French-led archaeologists and this restricted the access of the
              visitors for so many years. <br />
              The visitors started visiting the temple again in the year 2010
              partially. The visitors were not allowed to visit the central
              structure then also. It was in the year 2011, the construction
              work was completed after 51 years. King Norodom Sihamoni of
              Cambodia and Prime Minister Francois Fillon of France were among
              those who first toured the renovated temple during the
              inauguration ceremony on July 3, 2011.
            </p>
          </div>
        </div>
        {/* / welcome box \ */}
      </div>
    </>
  );
};

export default BaphuonTemple;
