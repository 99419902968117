import React from "react";
import image from "../../assets/images/The-Bayon.jpg";
const BayonTemple = () => {
  return (
    <>
      <div className="centerCol">
        {/* / welcome box \ */}
        <div className="contentBox">
          <h3>The Bayon Temple Ticket</h3>
          <div className="contant">
            <h4>The Bayon</h4>
            <p align="justify" className="floatL">
              <span className="floatR marLeft20">
                <img src={image} width={600} />
              </span>
              The most historical site present in A well-known, Khmer temple in
              Cambodia, The Bayon, is richly decorated and pleasing to the eyes.
              It was built as the official state temple in around the 12th or
              13th century for the Mahayana Buddhist king Jayavarman VII. The
              temple is in the center of the Angkor Thom, i.e., the capital of
              Jayavarman. But later on, when the king, Jayavarman, passed away,
              it was modified by the Hindu and the Ravada Buddhist kings for
              their religious preferences. <br />
              <br />
              What makes the Bayon different, from others is that it has serene
              and smiling stone faces on many of its towers. And this makes one
              of the distinctive features of this temple. These towers protrude
              from the terrace and cluster around the central peak. The temple
              also has two impressive sets of bas-reliefs. These present an
              unusual combination of mythological, historical, and some mundane
              scenes. The Japanese Government Team which is a conservatory body
              for the safeguarding of Angkor described the temple as, “the most
              striking expression of the baroque style” of Khmer architecture,
              as contrastedwith the classical style of Angkor Wat.
            </p>
          </div>
        </div>
        {/* / welcome box \ */}
      </div>
    </>
  );
};

export default BayonTemple;
