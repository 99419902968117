import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { commonApiCall } from "../service/api";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const PurchasePass = () => {
  const [heritagePlaceList, setHeritagePlace] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const heritagePlace = (nav) => {
    navigate("booking?place=" + nav);
  };

  useEffect(() => {
    getHeritageList();
  }, []);

  const getHeritageList = async () => {
    setLoading(true);
    try {
      const response = await commonApiCall("api/placeList", "get", {});
      if (response.status === 200) {
        setHeritagePlace(response.result);
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  return (
    <div>
      <div className="px-4 d-flex align-items-center">
        <div>
          <h4 className="header-title">Purchase Pass</h4>
          <p className="theme-text-color mb-0">Welcome to Purchase Platform</p>
        </div>
        <div className="right-header ms-auto d-md-flex d-none">
          <div className="icons d-flex me-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              viewBox="0 0 65 55"
              fit=""
              preserveAspectRatio="xMidYMid meet"
              focusable="false"
            >
              <g transform="translate(-1193 -160)">
                <rect
                  width="65"
                  height="55"
                  rx="18"
                  transform="translate(1193 160)"
                  fill="#319ee2"
                ></rect>
                <g transform="translate(1207.618 169.507)">
                  <path
                    d="M12.938,29.813a1.688,1.688,0,1,1-1.687-1.688,1.688,1.688,0,0,1,1.688,1.688Z"
                    fill="#fff"
                  ></path>
                  <path
                    d="M28.723,29.813a1.687,1.687,0,1,1-1.687-1.688,1.687,1.687,0,0,1,1.687,1.688Z"
                    fill="#fff"
                  ></path>
                  <path
                    d="M32.611,9.3a.413.413,0,0,0-.366-.3L9.345,6.785a.7.7,0,0,1-.527-.33,7.16,7.16,0,0,0-.858-1.308c-.541-.661-1.561-.64-3.431-.654a1.024,1.024,0,0,0-1.146.991,1,1,0,0,0,1.1.991,9.342,9.342,0,0,1,1.828.134c.33.1.6.64.7,1.111a.026.026,0,0,0,.007.021c.014.084.141.717.141.724L9.963,23.344a5.468,5.468,0,0,0,1.02,2.51A2.806,2.806,0,0,0,13.3,26.993H29.932a1,1,0,0,0,1.013-.942.98.98,0,0,0-.984-1.027H13.282a.817.817,0,0,1-.584-.2A3.155,3.155,0,0,1,11.89,23l-.3-1.666a.039.039,0,0,1,.028-.042l19.526-3.3a.411.411,0,0,0,.345-.366l1.125-8.142A.4.4,0,0,0,32.611,9.3Z"
                    fill="#fff"
                  ></path>
                </g>
              </g>
            </svg>
            <div className="ms-2">
              <p className="m-0">Orders</p>
              <p className="m-0">0</p>
            </div>
          </div>
          <div className="icons me-3 d-flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              viewBox="0 0 65 55"
              fit=""
              preserveAspectRatio="xMidYMid meet"
              focusable="false"
            >
              <g transform="translate(-1193 -160)">
                <rect
                  width="65"
                  height="55"
                  rx="18"
                  transform="translate(1193 160)"
                  fill="#00b19c"
                ></rect>
                <g transform="translate(1207.618 169.507)">
                  <path
                    d="M12.938,29.813a1.688,1.688,0,1,1-1.687-1.688,1.688,1.688,0,0,1,1.688,1.688Z"
                    fill="#fff"
                  ></path>
                  <path
                    d="M28.723,29.813a1.687,1.687,0,1,1-1.687-1.688,1.687,1.687,0,0,1,1.687,1.688Z"
                    fill="#fff"
                  ></path>
                  <path
                    d="M32.611,9.3a.413.413,0,0,0-.366-.3L9.345,6.785a.7.7,0,0,1-.527-.33,7.16,7.16,0,0,0-.858-1.308c-.541-.661-1.561-.64-3.431-.654a1.024,1.024,0,0,0-1.146.991,1,1,0,0,0,1.1.991,9.342,9.342,0,0,1,1.828.134c.33.1.6.64.7,1.111a.026.026,0,0,0,.007.021c.014.084.141.717.141.724L9.963,23.344a5.468,5.468,0,0,0,1.02,2.51A2.806,2.806,0,0,0,13.3,26.993H29.932a1,1,0,0,0,1.013-.942.98.98,0,0,0-.984-1.027H13.282a.817.817,0,0,1-.584-.2A3.155,3.155,0,0,1,11.89,23l-.3-1.666a.039.039,0,0,1,.028-.042l19.526-3.3a.411.411,0,0,0,.345-.366l1.125-8.142A.4.4,0,0,0,32.611,9.3Z"
                    fill="#fff"
                  ></path>
                  <path
                    d="M20.826,13.143l-.854-.878a.184.184,0,0,0-.136-.058h0a.176.176,0,0,0-.136.058l-5.919,5.962-2.154-2.154a.188.188,0,0,0-.272,0l-.864.864a.193.193,0,0,0,0,.277l2.717,2.717a.859.859,0,0,0,.568.277.9.9,0,0,0,.563-.267h0l6.486-6.52A.207.207,0,0,0,20.826,13.143Z"
                    transform="translate(3.46 -1.713)"
                    fill="#00b19c"
                  ></path>
                </g>
              </g>
            </svg>
            <div className="ms-2">
              <p className="m-0">Paid Orders</p>
              <p className="m-0">0</p>
            </div>
          </div>
          <div className="icons d-flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              viewBox="0 0 65 55"
              fit=""
              preserveAspectRatio="xMidYMid meet"
              focusable="false"
            >
              <g transform="translate(-1193 -160)">
                <rect
                  width="65"
                  height="55"
                  rx="18"
                  transform="translate(1193 160)"
                  fill="#f87e7e"
                ></rect>
                <g transform="translate(1207.618 169.507)">
                  <path
                    d="M12.938,29.813a1.688,1.688,0,1,1-1.687-1.688,1.688,1.688,0,0,1,1.688,1.688Z"
                    fill="#fff"
                  ></path>
                  <path
                    d="M28.723,29.813a1.687,1.687,0,1,1-1.687-1.688,1.687,1.687,0,0,1,1.687,1.688Z"
                    fill="#fff"
                  ></path>
                  <path
                    d="M32.611,9.3a.413.413,0,0,0-.366-.3L9.345,6.785a.7.7,0,0,1-.527-.33,7.16,7.16,0,0,0-.858-1.308c-.541-.661-1.561-.64-3.431-.654a1.024,1.024,0,0,0-1.146.991,1,1,0,0,0,1.1.991,9.342,9.342,0,0,1,1.828.134c.33.1.6.64.7,1.111a.026.026,0,0,0,.007.021c.014.084.141.717.141.724L9.963,23.344a5.468,5.468,0,0,0,1.02,2.51A2.806,2.806,0,0,0,13.3,26.993H29.932a1,1,0,0,0,1.013-.942.98.98,0,0,0-.984-1.027H13.282a.817.817,0,0,1-.584-.2A3.155,3.155,0,0,1,11.89,23l-.3-1.666a.039.039,0,0,1,.028-.042l19.526-3.3a.411.411,0,0,0,.345-.366l1.125-8.142A.4.4,0,0,0,32.611,9.3Z"
                    fill="#fff"
                  ></path>
                  <path
                    d="M2.667,0,0,4,2.667,8h8V0Zm2,1.04,2,2,2-2,.96.96-2,2,2,2-.96.96-2-2-2,2L3.707,6l2-2-2-2,.96-.96Z"
                    transform="translate(12.668 10.243)"
                    fill="#f87e7e"
                  ></path>
                </g>
              </g>
            </svg>
            <div className="ms-2">
              <p className="m-0">Unpaid Orders</p>
              <p className="m-0">0</p>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="temple-type mt-5">
        <h4 className="fw-bold text-center">
          Please select which pass to purchase
        </h4>

        {loading && (
          <div className="col-md-12 text-center my-5">
            <Skeleton width={250} height={270} borderRadius={20} />
          </div>
        )}

        <div className="d-flex flex-wrap justify-content-center my-5">
          {heritagePlaceList.map((place, index) => {
            return (
              <div className={""} key={index}>
                <Card
                  className={"bg-dark text-white me-3 mb-3"}
                  onClick={() => heritagePlace(encodeURIComponent(place.type))}
                  style={{
                    width: 250,
                    height: 270,
                    overflow: "hidden",
                    borderRadius: 20,
                  }}
                >
                  <Card.Img
                    src={
                      "images/" +
                      place.type.replaceAll(" ", "").toLowerCase() +
                      ".jpeg"
                    }
                    alt="Card image"
                    style={{
                      width: 250,
                      height: 270,
                      overflow: "hidden",
                      opacity: 0.8,
                    }}
                  />
                  <Card.ImgOverlay className="d-flex align-items-end w-100">
                    <Card.Title className="text-center">
                      {place.type}
                    </Card.Title>
                  </Card.ImgOverlay>
                </Card>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PurchasePass;
