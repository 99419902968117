import React from "react";

const Footer = () => {
  return (
    <>
      <div className="DisclaimerBox">
        <div className="centerCol">
          <h4>©Angkor Wat Tickets</h4>
          <p>
            Disclaimer: www.angkorenterprise.org website provides you Online
            services for Angkor temple. This is a commercial website to buy
            tickets for angkor temple where you will be charged a fee for using
            our services. You can also apply through the direct official source.
            Kindly read all our <a href="/tnc">Terms and Conditions</a>{" "}
            carefully before using our services.
          </p>
        </div>
      </div>
      {/* \ Disclaimers container \ */}
      {/* / servicesBox container \ */}
      {/* \ servicesBox container \ */}
      {/* / FooterBox container \ */}
      <div className="footerBox">
        <div className="centerCol">
          <div className="float-end">
            <ul>
              <li>
                <a href="#">
                  {" "}
                  <img src="/images/card-img1.png" alt />{" "}
                </a>
              </li>
              <li>
                <a href="#">
                  {" "}
                  <img src="/images/card-img2.png" alt />{" "}
                </a>
              </li>
              <li>
                <a href="#">
                  {" "}
                  <img src="/images/card-img3.png" alt />{" "}
                </a>
              </li>
            </ul>
          </div>
          <div className="copyright">
            {" "}
            ©Copyrights 2019 at Angkor Wat Tickets. All Rights Reserved{" "}
          </div>
        </div>
      </div>
      {/* \ FooterBox container \ */}
    </>
  );
};

export default Footer;
