import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";

const { commonApiCall } = require("./api");

const PayPalPaymentGateway = ({ paymentJson, callback }) => {
  return (
    <PayPalScriptProvider
      options={{
        "client-id": process.env.REACT_APP_PRODUCTION_PAYPAL_CLIENT_ID,

        // "AfE5j9bsIoO24fOsVITa3S3RBKrgHFZRowWYQMFTaI1YoqrpZMNehOE5hcQ1KD9ju6kdLPZf8eR1wDea",
      }}
    >
      <PayPalButtons
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: parseInt(paymentJson.amount) / 100,
                },
                description: "Ticket ID: " + paymentJson.temporary_id,
              },
            ],
          });
        }}
        onApprove={(data, actions) => {
          return actions.order.capture().then(async (details) => {
            console.log(details);
            let param = {
              order_id: details.id,
              status: null,
              payment_id: details.purchase_units[0].id,
              order_status:
                details.status === "VOIDED" ||
                details.status === "PAYER_ACTION_REQUIRED"
                  ? "Failed"
                  : "Confirmed",
              tran_id: null,
              temporary_id: paymentJson.temporary_id,
              pay_id: details.payer.payer_id,
              state: null,
              cart: null,
              payment_method: "paypal",
              payment_status: details.status,
              amount: details.purchase_units[0].amount.value,
              currency: details.purchase_units[0].amount.currency_code,
              description: null,
              invoice_number: null,
              payer_email: paymentJson.email,
              payer_name: paymentJson.name,
              create_time: null,
              ep_tran_id: null,
              visitors: paymentJson.visitors,
            };
            let response = await commonApiCall("api/payment", "post", param);
            if (response.status === 200) {
              callback(param);
            }
          });
        }}
        onCancel={async (data, actions) => {
          let failed = {
            order_id: paymentJson.receipt,
            status: null,
            payment_id: null,
            order_status: "Failed",
            tran_id: null,
            temporary_id: paymentJson.temporary_id,
            pay_id: null,
            state: null,
            cart: null,
            payment_method: "razorpay",
            payment_status: "failed",
            amount: paymentJson.amount,
            currency: paymentJson.currency,
            description: null,
            invoice_number: null,
            payer_email: null,
            payer_name: paymentJson.name,
            create_time: null,
            ep_tran_id: null,
          };
          let response = await commonApiCall("api/payment", "post", failed);
          if (response.status === 200) {
            callback(failed);
          }
        }}
      />
    </PayPalScriptProvider>
  );
};

export default PayPalPaymentGateway;
