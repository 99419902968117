import React from "react";

const Home = () => {
  return (
    <div className="main-wrapper">
      {/* <!-- / vissa container \ --> */}
      {/* <section class="bannerBox">
        <img src="images/banner.jpg" alt="" />
        <div class="pricecolam">
          <div class="ticket">
            Ticket <span>Prices</span>
          </div>
          <div class="daycolam">
            <div class="days">1 DAYS </div>
            <div class="price"> $37</div>
          </div>
          <div class="daycolam">
            <div class="days">3 DAYS </div>
            <div class="price"> $62</div>
          </div>
          <div class="daycolam mr">
            <div class="days">7 DAYS </div>
            <div class="price"> $72</div>
          </div>
          <div class="buyBTN">
            <a href="/ticket">Buy Now</a>
          </div>
        </div>
      </section> */}
      {/* <!-- \ vissa container \ --> */}
      <div class="centerCol">
        {/* <!-- / welcome box \ --> */}
        <div class="contentBox">
          <h3>Angkor Wat Temple Ticket</h3>

          <div class="contant">
            <h4>Angkor Wat</h4>
            <p>
              The most historical site present in Southeast Asia is the Angkor.
              It is located in Siem Reap’s northern province, Cambodia. Spread
              over almost 400 square km consists of several temples, basins,
              dykes, reservoirs, and canals, these all are hydraulic structures
              and routes for communication. For the Khmer kingdom, Angkor had
              been a center for many centuries. Angkor wat was initially
              designed and constructed in the first half of the 12 th century,
              during the reign of Suryavarman II (ruled 1113 – c. 1150), as the
              king’s state temple and capital city. It was built without the aid
              of any machinery, as there was no machinery available at that
              time. The construction of Angkor Wat is thought to have taken 35
              years, 300,000 laborers, and 6000 elephants, according to
              inscriptions.
            </p>
          </div>

          <div class="contant mb0">
            <p>
              This site has features of exceptional civilization like impressive
              monuments, several different ancient urban plans, and large water
              reservoirs. At present also, most of the population has converted
              to Buddhists, which plays an important role in Cambodia. The
              number of tourists has risen considerably over the past decade.
              After extensive restoration work due to damage, deterioration, and
              natural elements taking their toll, such as plant growth and
              ground movements from earthquakes, the monument is one of the most
              visited in Cambodia. In 2013, the number of visitors to the site
              reached over two million and continues to grow today. Projects
              have been introduced to preserve the monuments as much as possible
              in line with the growing number of tourists. Ropes and wooden
              steps have been installed to aid wear and tear on the low-reliefs
              and floors in particular. Buddhist monks and nuns worship at the
              site.
            </p>
            <p>
              It was listed as a UNESCO World Heritage Site in 1992, which
              encouraged an international effort to save the complex.
            </p>
          </div>
        </div>
        {/* /    <!-- / welcome box \ --> */}

        {/* <!-- / travelBox container \ --> */}
        <div class="travelBox">
          <div class="centerimg">
            <div class="panel">
              <div class="colam">
                <div class="left">
                  <img src="images/tervel-icon1.png" alt="" />
                </div>
                <div class="right">
                  <h3>Travelling to Siem Reap</h3>
                  <p>
                    The charming and emerging town Siem Reap is the gateway the
                    Angkor Archaeological Park.
                  </p>
                </div>
              </div>
              <div class="colam">
                <div class="left">
                  <img src="images/tervel-icon2.png" alt="" />
                </div>
                <div class="right">
                  <h3>Where to stay</h3>
                  <p>
                    From luxury 5 stars hotels to the budget-fiendly guest
                    houses, Siem Reap provides you the unique experience while
                    staying in Cambodia.
                  </p>
                </div>
              </div>

              <div class="colam">
                <div class="left">
                  <img src="images/tervel-icon3.png" alt="" />
                </div>
                <div class="right">
                  <h3>Information</h3>
                  <p>Access information to the Angkor Archaeological Park.</p>
                </div>
              </div>
            </div>

            <div class="panel floatR">
              <div class="colam">
                <div class="left">
                  <img src="images/tervel-icon4.png" alt="" />
                </div>
                <div class="right">
                  <h3>Travelling to Siem Reap</h3>
                  <p>
                    Travelling around Siem Reap town is covenient. The town
                    offers variety of travel experience.
                  </p>
                </div>
              </div>
              <div class="colam">
                <div class="left">
                  <img src="images/tervel-icon5.png" alt="" />
                </div>
                <div class="right">
                  <h3>Activities & Attractions</h3>
                  <p>
                    From luxury 5 stars hotels to the budget-fiendly guest
                    houses, Siem Reap provides you the unique experience while
                    staying in Cambodia.
                  </p>
                </div>
              </div>

              <div class="colam">
                <div class="left  ml">
                  <img src="images/tervel-icon6.png" alt="" />
                </div>
                <div class="right">
                  <p>
                    Apply for a Single Tourist entry visa and pay securely using
                    your VISACard, Mastercard or Unionpay.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- \ travelBox container \ --> */}

        {/* <!-- / templeBox container \ --> */}
        <div class="templeBox">
          <div class="textcolam">
            <h3>Temples</h3>
            <p>
              Over one thousand of temples are waiting to be discovered. Each
              temple is uniques, and provide the unique experience of your
              visit.
            </p>
          </div>
          <div class="list">
            <ul>
              <li>
                <div class="inner">
                  <h4> Angkor Wat</h4>
                  <div class="image">
                    <img src="images/angkor-wat.jpg" alt="" />
                  </div>
                  <p>
                    The largest religious monument in the world is none other
                    than Angkor Wat which is a temple complex. Originally it was
                    a Hindu temple which is in 162.6 hectares. it was dedicated
                    to lord Vishnu for
                  </p>
                  <h5>
                    Opening Time: <span> </span>05:00 - 17:00
                  </h5>
                  <div class="more">
                    <a href="/temples/wattemple">More</a>
                  </div>
                </div>
              </li>
              <li>
                <div class="inner">
                  <h4> Bayon</h4>
                  <div class="image">
                    <img src="images/bayon.jpeg" alt="" />
                  </div>
                  <p>
                    A well-known, Khmer temple in Cambodia, The Bayon, is richly
                    decorated and pleasing to the eyes. It was built as the
                    official state temple in around the 12th or 13th century
                    century for the Mahayana Buddhist
                  </p>
                  <h5>
                    Opening Time: <span> </span>05:00 - 17:00
                  </h5>
                  <div class="more">
                    <a href="/temples/bayontemple">More</a>
                  </div>
                </div>
              </li>
              <li>
                <div class="inner">
                  <h4> Bakheng Temple</h4>
                  <div class="image">
                    <img src="images/bakheng-temple.jpeg" alt="" />
                  </div>
                  <p>
                    The temple, Phnom Bakheng, which is situated in Angkor,
                    Cambodia is in a form of a temple mountain which is a Hindu
                    and Buddhist temple. It was built by the end of the 9th
                    century
                  </p>
                  <h5>
                    Opening Time: <span> </span>05:00 - 17:00
                  </h5>
                  <div class="more">
                    <a href="/temples/bakhengtemple">More</a>
                  </div>
                </div>
              </li>
              <li>
                <div class="inner">
                  <h4> Baphuon Temple</h4>
                  <div class="image">
                    <img src="images/baphuon-temple.jpeg" alt="" />
                  </div>
                  <p>
                    Located in Angkor Thom, northwest of the Bayon is the
                    Baphuon temple. It is a three-tier mountain temple built in
                    the mid of 11th century as a state temple of
                    Udayadityavarman.
                  </p>
                  <h5>
                    Opening Time: <span> </span>05:00 - 17:00
                  </h5>
                  <div class="more">
                    <a href="/temples/baphuontemple">More</a>
                  </div>
                </div>
              </li>
              <li>
                <div class="inner">
                  <h4> Banteay Srei Templee</h4>
                  <div class="image">
                    <img src="images/banteay-srei-temple.jpeg" alt="" />
                  </div>
                  <p>
                    This temple is a 10th-century Cambodian temple located in
                    the Angkor region. It is dedicated to Lord Shiva, near the
                    hill of Phnom Dei. This hill is located in the northeast of
                    the main group of temples,
                  </p>
                  <h5>
                    Opening Time: <span> </span>05:00 - 17:00
                  </h5>
                  <div class="more">
                    <a href="/temples/banteaysritemple">More</a>
                  </div>
                </div>
              </li>
              <li>
                <div class="inner">
                  <h4> Preah Khan Temple</h4>
                  <div class="image">
                    <img src="images/preah-khan-temple.jpeg" alt="" />
                  </div>
                  <p>
                    This temple was built, in Angkor Cambodia for king
                    Jayavarman VII, in the 12th century to honor his father. It
                    had almost 100000 officials and servants and was considered
                    the center of a substantial organization.
                  </p>
                  <h5>
                    Opening Time: <span> </span>05:00 - 17:00
                  </h5>
                  <div class="more">
                    <a href="/temples/preahkhantemple">More</a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        {/* <!-- \ servicesBox container \ --> */}
      </div>

      {/* <!-- / Disclaimers container \ --> */}

      {/* <!-- \ Disclaimers container \ --> */}

      {/* <!-- / servicesBox container \ --> */}
      {/* <div class="servicesBox">
        <div class="centerCol">
          <ul>
            <li>Ministry of Economy and Finance</li>
            <li>Apsara Authority</li>
            <li>Ministry of Tourism</li>
            <li>Ministry of Culture and Fine Arts</li>
          </ul>
        </div>
      </div> */}
      {/* <!-- \ servicesBox container \ --> */}
    </div>
  );
};

export default Home;
