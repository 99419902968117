import React from "react";
import banner from ".././assets/images/banner.jpg";
const Banner = () => {
  return (
    <>
      <section className="bannerBox">
        {" "}
        <img src={banner} alt />
        <div className="pricecolam">
          {/*-<div class="ticket">Ticket <span>Prices</span></div>-*/}
          <div className="daycolam">
            <div className="days">1 DAYS </div>
            <div className="price"> Ticket</div>
          </div>
          <div className="daycolam">
            <div className="days">3 DAYS </div>
            <div className="price"> Ticket</div>
          </div>
          <div className="daycolam mr">
            <div className="days">5 DAYS </div>
            <div className="price"> Ticket</div>
          </div>
          <div className="buyBTN">
            <a href="/ticket">Buy Now</a>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
