import React from "react";
import { Link, Outlet, Route, Routes } from "react-router-dom";
import PurchasePass from "../screens/purchasePass";

const TicketMainLayout = () => {
  return (
    <div className="ticket-main-layout">
      <div className="container-fluid">
        <div className="row pt-3">
          <div className="col-md-2 d-none d-md-block">
            <div className="left-side-container shadow">
              <ul className="list-unstyled">
                <li className="mt-3">
                  <Link to={"/ticket"} className="active">
                    <i className="fa-solid fa-cart-flatbed me-2"></i> Ticket
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-10">
            <div className="right-side-container shadow">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketMainLayout;
