import axios from "axios";

import { commonApiCall } from "./api";
import config, { BASE_URL, LOCAL_BASE_URL } from "./config";

export const payment = {
  displayRazorpay,
};

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const __DEV__ = document.domain === "localhost";

async function displayRazorpay(params, callback) {
  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

  if (!res) {
    alert("Razorpay SDK failed to load. Are you online?");
    return;
  }

  const data = await axios({
    method: "post",
    url: `${__DEV__ ? LOCAL_BASE_URL : BASE_URL}api/razorpay`, //${config.baseUrl}RazorPay/RazorPayPaymentTicket
    data: params,
  }).then((t) => t.data); ///(t.data.orderdetails ? t.data.orderdetails.attributes : null)

  const options = {
    key: __DEV__
      ? process.env.REACT_APP_PAYMENT_API_KEY
      : process.env.PRODUCTION_KEY,
    currency: data.currency, //data.currency
    amount: data.amount.toString(),
    order_id: data.id,
    name: "Angkor Wat Tickets",
    description: params.temporary_id,
    image: "",
    handler: async function (response) {
      // const param = {
      //   order_id: params.receipt,
      //   status: "success",
      //   payment_id: response.razorpay_payment_id,
      //   order_status: "Confirmed",
      //   amount: data.amount.toString(),
      //   email: params.email,
      // };
      let param;
      if (
        typeof response.razorpay_payment_id == "undefined" ||
        response.razorpay_payment_id < 1
      ) {
        param = {
          order_id: params.receipt,
          status: null,
          payment_id: response.error.metadata.payment_id,
          order_status: "Failed",
          tran_id: null,
          temporary_id: params.temporary_id,
          pay_id: response.error.metadata.payment_id,
          state: null,
          cart: null,
          payment_method: "razorpay",
          payment_status: response.error.reason,
          amount: (data.amount / 100).toString(),
          currency: data.currency,
          description: null,
          invoice_number: null,
          payer_email: null,
          payer_name: params.name,
          create_time: null,
          ep_tran_id: null,
        };
      } else {
        param = {
          // order_id: params.receipt,
          // status: "success",
          // payment_id: response.razorpay_payment_id,
          // order_status: "Confirmed",
          // amount: (data.amount / 100).toString(),
          // email: params.email,

          order_id: params.receipt,
          status: null,
          payment_id: response.razorpay_payment_id,
          order_status: "Confirmed",
          tran_id: null,
          temporary_id: params.temporary_id,
          pay_id: response.razorpay_payment_id,
          state: null,
          cart: null,
          payment_method: "razorpay",
          payment_status: "success",
          amount: (data.amount / 100).toString(),
          currency: data.currency,
          description: null,
          invoice_number: null,
          payer_email: params.email,
          payer_name: params.name,
          create_time: null,
          ep_tran_id: null,
          visitors: params.visitors,
        };
      }
      let statusResponse = await commonApiCall("api/payment", "post", param);
      if (statusResponse.status === 200) {
        if (typeof callback === "function") {
          callback(param);
        }
      } else {
        // showErrorToast(statusResponse.data.msg);
      }
    },
    modal: {
      ondismiss: async function () {
        let failed = {
          order_id: params.receipt,
          status: null,
          payment_id: null,
          order_status: "Failed",
          tran_id: null,
          temporary_id: params.temporary_id,
          pay_id: null,
          state: null,
          cart: null,
          payment_method: "razorpay",
          payment_status: "failed",
          amount: (data.amount / 100).toString(),
          currency: data.currency,
          description: null,
          invoice_number: null,
          payer_email: null,
          payer_name: params.name,
          create_time: null,
          ep_tran_id: null,
        };
        let statusResponse = await commonApiCall("api/payment", "post", failed);
        if (statusResponse.status === 200) {
          if (typeof callback === "function") {
            callback(failed);
          }
        } else {
          // showErrorToast(statusResponse.data.msg);
        }
      },
    },
    prefill: {
      name: localStorage.getItem("customer_name"),
      email: localStorage.getItem("email"),
      phone_number: localStorage.getItem("mobile"),
    },
  };
  const paymentObject = new window.Razorpay(options);

  paymentObject.on("payment.failed", async function (response) {
    if (response) {
      const param = {
        order_id: params.receipt,
        status: response.error.reason,
        payment_id: response.error.metadata.payment_id,
        currency: data.currency,
        payer_name: params.name,
      };
      let response = await commonApiCall("api/payment", "post", param);
      if (response.status === 200) {
        if (typeof callback === "function") {
          callback(param);
        }
      } else {
        // showErrorToast(response.data.msg);
      }
    }
  });
  paymentObject.open();
}
