import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import Skeleton from "react-loading-skeleton";
import { commonApiCall } from "../../service/api";

const TicketSelection = ({ data, onTicketChange, error, ...props }) => {
  const urlPlace = new URLSearchParams(window.location.search).get("place");

  const [loading, setLoading] = useState(false);
  const [ticketList, setTicketList] = useState([]);
  const onDateChange = (date) => {
    onTicketChange("date", date);
  };

  const setLabel = (price) => onTicketChange("price", price);

  useEffect(() => {
    ticketTypeList();
  }, []);

  const ticketTypeList = async () => {
    setLoading(true);
    try {
      const response = await commonApiCall(
        "api/ticketList",
        "post",
        urlPlace ? { type: decodeURIComponent(urlPlace) } : {}
      );
      if (response.status === 200) {
        setTicketList(response.result);
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };
  return (
    <React.Fragment>
      <div className="ticket-selection mt-4 px-2">
        <div className="ticket-type px-3">
          <p>
            <svg
              version="1.2"
              baseProfile="tiny-ps"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 18 20"
              width="100%"
              height="100%"
              fit=""
              preserveAspectRatio="xMidYMid meet"
              focusable="false"
              style={{ width: 20 }}
            >
              <path
                id="Layer"
                fillRule="evenodd"
                class="shp0"
                d="M18 0L18 20L16.5 18.5L15 20L13.5 18.5L12 20L10.5 18.5L9 20L7.5 18.5L6 20L4.5 18.5L3 20L1.5 18.5L0 20L0 0L1.5 1.5L3 0L4.5 1.5L6 0L7.5 1.5L9 0L10.5 1.5L12 0L13.5 1.5L15 0L16.5 1.5L18 0ZM16 2.91L2 2.91L2 17.09L16 17.09L16 2.91ZM3 13L15 13L15 15L3 15L3 13ZM3 9L15 9L15 11L3 11L3 9ZM3 5L15 5L15 7L3 7L3 5Z"
              ></path>
              <path
                id="Shape 1 copy 2"
                class="shp1"
                d="M3 5L15 5L15 7L3 7L3 5Z"
              ></path>
              <path
                id="Shape 1 copy 3"
                class="shp1"
                d="M3 9L15 9L15 11L3 11L3 9Z"
              ></path>
              <path
                id="Shape 1 copy 4"
                class="shp1"
                d="M3 13L15 13L15 15L3 15L3 13Z"
              ></path>
            </svg>{" "}
            Select type of tickets <sup>*</sup>
            <span className="text-danger ms-2">{error?.price}</span>
          </p>
          <div className="d-flex justify-content-between flex-wrap w-md-75 mx-auto ticket-day">
            {loading && (
              <div className="d-flex justify-content-between w-100 flex-wrap">
                <Skeleton width={112} className="mr-3" height={124} />
                <Skeleton width={112} className="mr-3" height={124} />
                <Skeleton width={112} height={124} />
              </div>
            )}
            {ticketList.length > 0 &&
              ticketList.map((ticket, index) => {
                return (
                  <label
                    key={index}
                    onClick={() => setLabel(ticket)}
                    className={data?.price === ticket.price ? "active " : ""}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="80"
                      viewBox="0 0 112.81 93.98"
                    >
                      <path
                        d="M104.65,107H15.35A11.78,11.78,0,0,1,3.59,95.24v-9.4a2.35,2.35,0,0,1,2.35-2.35H8.29a9.4,9.4,0,1,0,0-18.8H5.94a2.35,2.35,0,0,1-2.35-2.35h0v-9.4A11.78,11.78,0,0,1,15.35,41.18h89.3a11.78,11.78,0,0,1,11.76,11.75v9.4a2.35,2.35,0,0,1-2.35,2.35h-2.35a9.4,9.4,0,1,0,0,18.8h2.35a2.35,2.35,0,0,1,2.35,2.35v9.4A11.77,11.77,0,0,1,104.65,107ZM8.29,88.19v7a7.07,7.07,0,0,0,7.06,7.05h89.3a7.07,7.07,0,0,0,7.06-7.05v-7h0a14.1,14.1,0,1,1,0-28.2V52.93a7.07,7.07,0,0,0-7.06-7H15.35a7.06,7.06,0,0,0-7.06,7v7a14.1,14.1,0,0,1,0,28.2Z"
                        transform="translate(-3.59 -13.01)"
                      ></path>
                      <path
                        d="M15.34,45.88a2.35,2.35,0,0,1-.83-4.55L89.25,13.42a7.2,7.2,0,0,1,9.15,4.34l8.47,25a2.35,2.35,0,0,1-4.42,1.61l0-.1-8.48-25a2.4,2.4,0,0,0-3-1.44L16.16,45.73a2.29,2.29,0,0,1-.82.15Z"
                        transform="translate(-3.59 -13.01)"
                      ></path>
                      <g>
                        <text
                          x="50%"
                          y="67%"
                          dominantBaseline="middle"
                          textAnchor="middle"
                          style={{
                            isolation: "isolate",
                            fontSize: 18,
                            fontWeight: 700,
                          }}
                        >
                          {" "}
                          {ticket.days} Days{" "}
                        </text>
                      </g>
                    </svg>
                    {/* <p>${ticket.price}.00</p> */}
                  </label>
                );
              })}
          </div>
          <p className="my-4">
            <strong>Note: </strong>You can visit for {data.days} days before
            expiry date.
          </p>
          <p>
            <svg
              className="me-2"
              width="100%"
              height="100%"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              xmlnsSvgjs="http://svgjs.com/svgjs"
              viewBox="439 677 26 25"
              fit=""
              preserveAspectRatio="xMidYMid meet"
              focusable="false"
              style={{ width: 24 }}
            >
              <svg
                width="3"
                height="4"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                xmlnsSvgjs="http://svgjs.com/svgjs"
                viewBox="455 677 3 4"
                x="455"
                y="677"
              >
                <svg
                  width="3"
                  height="4"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  xmlnsSvgjs="http://svgjs.com/svgjs"
                  viewBox="455 677 3 4"
                  x="455"
                  y="677"
                >
                  <svg
                    width="3"
                    height="4"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xmlnsSvgjs="http://svgjs.com/svgjs"
                    viewBox="0.279 0 2.441 4"
                    fill="#1b8e78"
                    x="455"
                    y="677"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="3"
                      height="4"
                      viewBox="0.171 0 2.103 3.446"
                    >
                      <path
                        paint-order="stroke fill markers"
                        fill-rule="evenodd"
                        d="M1.242 0h-.025A1.028 1.028 0 0 0 .172 1.01l-.001.033v2.403h2.103V1.043A1.038 1.038 0 0 0 1.242 0z"
                      ></path>
                    </svg>
                  </svg>
                </svg>
              </svg>
              <svg
                width="22"
                height="5"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                xmlnsSvgjs="http://svgjs.com/svgjs"
                viewBox="439 679 22 5"
                x="439"
                y="679"
              >
                <svg
                  width="22"
                  height="5"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  xmlnsSvgjs="http://svgjs.com/svgjs"
                  viewBox="439 679 22 5"
                  x="439"
                  y="679"
                >
                  <svg
                    width="22"
                    height="5"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xmlnsSvgjs="http://svgjs.com/svgjs"
                    viewBox="0 0.418 22 4.164"
                    fill="#1b8e78"
                    x="439"
                    y="679"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="22"
                      height="5"
                      viewBox="0.928 0.57 20.674 3.913"
                    >
                      <path
                        paint-order="stroke fill markers"
                        fill-rule="evenodd"
                        d="M19.694.57h-.486v1.356a.445.445 0 0 1-.454.454H15.68a.436.436 0 0 1-.443-.43v-.024V.57H7.294v1.356a.47.47 0 0 1-.482.454H3.74a.463.463 0 0 1-.475-.45v-.004V.57h-.436a1.91 1.91 0 0 0-1.9 1.909v2.004h20.674V2.479a1.918 1.918 0 0 0-1.91-1.91z"
                      ></path>
                    </svg>
                  </svg>
                </svg>
              </svg>
              <svg
                width="3"
                height="4"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                xmlnsSvgjs="http://svgjs.com/svgjs"
                viewBox="443 677 3 4"
                x="443"
                y="677"
              >
                <svg
                  width="3"
                  height="4"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  xmlnsSvgjs="http://svgjs.com/svgjs"
                  viewBox="443 677 3 4"
                  x="443"
                  y="677"
                >
                  <svg
                    width="3"
                    height="4"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xmlnsSvgjs="http://svgjs.com/svgjs"
                    viewBox="0.246 0 2.508 4"
                    fill="#1b8e78"
                    x="443"
                    y="677"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="3"
                      height="4"
                      viewBox="0.199 0 2.16 3.446"
                    >
                      <path
                        paint-order="stroke fill markers"
                        fill-rule="evenodd"
                        d="M1.276 0C.686 0 .2.453.2 1.043v2.403h2.16V1.043C2.36.453 1.866 0 1.276 0z"
                      ></path>
                    </svg>
                  </svg>
                </svg>
              </svg>
              <svg
                width="22"
                height="16"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                xmlnsSvgjs="http://svgjs.com/svgjs"
                viewBox="439 684 22 16"
                x="439"
                y="684"
              >
                <svg
                  width="22"
                  height="16"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  xmlnsSvgjs="http://svgjs.com/svgjs"
                  viewBox="439 684 22 16"
                  x="439"
                  y="684"
                >
                  <svg
                    width="22"
                    height="16"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xmlnsSvgjs="http://svgjs.com/svgjs"
                    viewBox="0 0.17 22 15.661"
                    fill="#1b8e78"
                    x="439"
                    y="684"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="22"
                      height="16"
                      viewBox="0.928 0.417 20.674 14.717"
                    >
                      <path
                        paint-order="stroke fill markers"
                        fill-rule="evenodd"
                        d="M.928.417v12.785c0 1.048.853 1.932 1.9 1.932h8.658a8.013 8.013 0 0 1-1.129-4.085c0-4.357 3.547-7.917 7.903-7.917a8 8 0 0 1 3.343.74V.418H.928z"
                      ></path>
                    </svg>
                  </svg>
                </svg>
              </svg>
              <svg
                width="15"
                height="14"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                xmlnsSvgjs="http://svgjs.com/svgjs"
                viewBox="450 688 15 14"
                x="450"
                y="688"
              >
                <svg
                  width="15"
                  height="14"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  xmlnsSvgjs="http://svgjs.com/svgjs"
                  viewBox="450 688 15 14"
                  x="450"
                  y="688"
                >
                  <svg
                    width="15"
                    height="14"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xmlnsSvgjs="http://svgjs.com/svgjs"
                    viewBox="0.5 0 14 14"
                    fill="#e5b000"
                    x="450"
                    y="688"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="14"
                      viewBox="0.289 0.066 13.934 13.934"
                    >
                      <path
                        paint-order="stroke fill markers"
                        fill-rule="evenodd"
                        d="M7.256.066a6.967 6.967 0 1 0 6.967 6.967A6.975 6.975 0 0 0 7.256.066zm2.752 8.031H6.876a.487.487 0 0 1-.478-.478V3.52a.467.467 0 0 1 .934 0v3.642h2.676a.467.467 0 0 1 0 .934z"
                      ></path>
                    </svg>
                  </svg>
                </svg>
              </svg>
            </svg>
            Pick a date of entry <sup>*</sup>
            <span className="text-danger ms-2">{error?.date}</span>
          </p>
          <div className="text-center">
            <DatePicker
              selected={data?.date}
              onChange={onDateChange}
              minDate={new Date()}
              inline
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TicketSelection;
