import axios from "axios";
import { BASE_URL, LOCAL_BASE_URL } from "./config";
import { toast } from "react-toastify";
import "react-toastify/ReactToastify.css";

const __DEV__ = document.domain === "localhost";
export const commonApiCall = async (endpoint, method, body) => {
  return await axios({
    method: method,
    url: (__DEV__ ? LOCAL_BASE_URL : BASE_URL) + endpoint,
    data: body,
  }).then((response) => response.data);
};

export const showSuccessToast = (msg) => {
  toast(msg, {
    type: "success",
    position: "bottom-right",
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: "colored",
  });
};

export const showErrorToast = (msg) => {
  console.log(msg);
  toast(msg, {
    type: "error",
    position: "bottom-right",
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: "colored",
  });
};
