import React from "react";
import image from "../../assets/images/Banteay-Srei-Lady-Temple-Cambodia-16.jpg";
const BanteaySritemple = () => {
  return (
    <>
      <div className="centerCol">
        {/* / welcome box \ */}
        <div className="contentBox">
          <h3>Banteay Srei Temple Ticket</h3>
          <div className="contant">
            <h4>Banteay Srei Temple</h4>
            <p align="justify" className="floatL">
              <span className="floatR marLeft20">
                <img src={image} width={600} />
              </span>
              The most historical site present in This temple is a 10th-century
              Cambodian temple located in the Angkor region. It is dedicated to
              Lord Shiva, near the hill of Phnom Dei. This hill is located in
              the northeast of the main group of temples, which belonged to the
              medieval capitals of Yasodharapura and Angkor Thom. The buildings
              of this temple are miniature in scale when measured by the
              standards of Angkorian construction.
              <br />
              <br />
              Banteay Srei Temple is constructed with red sandstone, its wall
              has elaborative carvings which can be observed even today. It is
              popularly also known as a “precious gem” or the “jewel of Khmer
              art”. It is very popular among tourists and is praised all over
              the world for its beauty.
              <br />
              One of the main things about this temple is that it is not built
              by any monarch, but by the courtiers named Vishnukumara and
              Yajnavaraha, consecrated on 22 April 967 AD. It was Yajnavaraha
              who served as a counselor to king Rajendravarman II. It is also
              believed that Yajnavaraha was a scholar and a philanthropist. He
              was the grandson of king Harsavarman I, 117 AD, and helped the
              ones who suffered from illness, injustice, or poverty. Originally,
              this temple, Banteay Srei, was surrounded by a town known as
              Isvarapura. Yajnavaraha’s one of the pupils became future king
              Jayavarman V (968 – 1001).
              <br />
              <br />
              Firstly, this temple was dedicated to Lord Shiva and carried the
              nameTribhuvanamahesvara, which meant great lord of the threefold
              world, it represents the Shaivite Linga. The building of the
              temple seems to be divided, in certain directions, like the
              east-west axis, the south axis is devoted to Siva, and the north
              axis is devoted to Visnu.
            </p>
          </div>
        </div>
        {/* / welcome box \ */}
      </div>
    </>
  );
};

export default BanteaySritemple;
