import React from "react";

const ContactUs = () => {
  return (
    <>
      <div className="centerCol">
        {/* / welcome box \ */}
        <div className="contentBox">
          <h3>Contact Us</h3>
          <div className="contant">            
            <h4>
              Email:{" "}
              <a href="mailto:info@angkorenterprise.org">
                info@angkorenterprise.org
              </a>
            </h4>
            {/* <h4>Contact No. <a href="tel:7982295275">+91-7982295275</a></h4>
            <h4>
                Address: <span>Rivaan Enterprises, D-104, Sector-4, Dwarka, New
                Delhi-110078</span>
            </h4> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
