import moment from "moment";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { payment } from "./payment";

const PaymentResponse = ({ updatePayment }) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  // const getPaymentState =;

  return (
    <div className="payment-response-main p-3">
      {/* <h2 className="mb-4">
        Payment{" "}
        {state?.data?.payment_status === "success" ||
        state?.data?.payment_status === "COMPLETED"
          ? "Successful"
          : "Failed"}
      </h2> */}
      {(state?.data?.payment_status === "success" ||
        state?.data?.payment_status === "COMPLETED") && (
        <>
          <h5 className="mb-3">Thank You!</h5>
          <p>Dear {state?.paymentJson?.name},</p>
          <p>
            Your ticket purchase has been completed successfully. you will
            receive a confirmation email from info@angkorenterprise.org. The
            Angkor Fees once paid is non-refundable for any reason.
          </p>
          <p className="fw-bold">Your Booking details as follows :</p>
          <p className="mb-1">Amount Paid: ${state?.data?.amount} </p>
          <p className="mb-1">Booking Id: {state?.data?.temporary_id} </p>
          <p className="mb-1">
            Date of trip:{" "}
            {moment(state?.paymentJson?.date_of_entry).format("DD/MM/YYYY")}{" "}
          </p>
          <p className="mb-1">Email Id: {state?.data?.payer_email} </p>
          <p className="mb-3">
            Number of people: {state?.paymentJson?.visitors}{" "}
          </p>

          <p>
            <b>Note:</b> If you do not see the email in a few minutes, check
            your “junk mail” folder or “spam” folder. We make every effort to
            ensure that these emails are delivered. If you still don’t receive
            an email, then write to{" "}
            <a href="mailto:info@angkorenterprise.org">
              {" "}
              info@angkorenterprise.org
            </a>{" "}
            explaining the problem. We will do our best to answer your request
            as soon as possible.
          </p>
          <p>
            If you have questions about this payment, please contact us :-
            <a href="mailto:info@angkorenterprise.org">
              {" "}
              info@angkorenterprise.org
            </a>
          </p>
          {/* <button className="btn btn-primary">
            Click here to Download e-ticket
          </button> */}
        </>
      )}
      {(state?.data?.payment_status === "failed" ||
        (state?.data?.status && state?.data?.status !== "COMPLETED")) && (
        <>
          <h2 className="mb-4">
            Payment{" "}
            {state?.data?.payment_status === "success" ||
            state?.data?.payment_status === "COMPLETED"
              ? "Successful"
              : "Failed"}
          </h2>
          <h5>Could not generate tickets.</h5>
          <p>If your amount is debited, it will be auto refunded</p>
          <p>Reference Id : {state?.data?.temporary_id}</p>
          <p>
            Please{" "}
            <button
              className="btn btn-link  p-0 ms-2"
              onClick={() => {
                payment.displayRazorpay(state?.paymentJson, (data) => {
                  updatePayment(data);
                  navigate("/ticket/response", {
                    state: { data, paymentJson: state?.paymentJson },
                  });
                });
              }}
            >
              try again
            </button>
          </p>
        </>
      )}
      <p>
        {" "}
        <button
          className="btn btn-link text-white p-0 ms-2"
          onClick={() => window.location.reload()}
        >
          Back to home
        </button>
      </p>
    </div>
  );
};

export default PaymentResponse;
