import React, { useState } from "react";
import Image from "../../assets/images/image-1.jpg";
import { Link } from "react-router-dom";
import TempleMenu from "../../layout/templeMenu";

const TempleData = () => {
  return (
    <>
      <div className="centerCol">
        {/* / welcome box \ */}
        <div
          className="contentBox"
          style={{ width: "100%", float: "left", display: "block" }}
        >
          <div>
            <p>&nbsp;</p>
            <p>
              <span className="floatR marLeft20 shadow">
                <img src={Image} width={560} />
              </span>
            </p>
            <h4>Angkor Wat</h4>
            <p>
              <strong>Time:</strong> 05:00 - 17:30
            </p>
            <p>
              <strong>Place:</strong> Siem Reap
            </p>
            <p>
              The most historical site present in Southeast Asia is the Angkor.
              It is located in Siem Reap’s northern province, Cambodia. Spread
              over almost 400 square km consists of several temples, basins,
              dykes, reservoirs, and canals, these all are hydraulic structures
              and routes for communication. For the Khmer kingdom, Angkor had
              been a center for many centuries.
            </p>
            <p>&nbsp;</p>
            <p className="moreBtn">
              <Link to="wattemple">Read more</Link>
            </p>
            <p className="clear">&nbsp;</p>
            <p>&nbsp;</p>
            <p>
              <span className="floatL marRight20 shadow">
                <img src={Image} width={560} />
              </span>
            </p>
            <h4>Bayon</h4>
            <p>
              <strong>Time:</strong> 07:30 - 17:30
            </p>
            <p>
              <strong>Place:</strong> Siem Reap, Cambodia
            </p>
            <p>
              The most historical site present in A well-known, Khmer temple in
              Cambodia, The Bayon, is richly decorated and pleasing to the eyes.
              It was built as the official state temple in around the 12th or
              13th century for the Mahayana Buddhist king Jayavarman VII. The
              temple is in the center of the Angkor Thom, i.e., the capital of
              Jayavarman. But later on, when the king, Jayavarman, passed away,
              it was modified by the Hindu and the Ravada Buddhist kings for
              their religious preferences.
            </p>
            <p>&nbsp;</p>
            <p className="moreBtn">
              <Link to="bayontemple">Read More</Link>
            </p>
            <p className="clear">&nbsp;</p>
            <p>&nbsp;</p>
            <p>
              <span className="floatR marLeft20 shadow">
                <img src={Image} width={560} />
              </span>
            </p>
            <h4>Bakheng Temple</h4>
            <p>
              <strong>Time:</strong> 05:00 - 17:30
            </p>
            <p>
              <strong>Place:</strong> Siem Reap
            </p>
            <p>
              The most historical site present in The temple, Phnom Bakheng,
              which is situated in Angkor, Cambodia is in a form of a temple
              mountain which is a Hindu and Buddhist temple. It was built by the
              end of the 9th century during the reign of king Yasovarman
              (889-910) and is dedicated to Lord Shiva. It has become a popular
              tourist spot as it is located at the top of the hill, people enjoy
              the sunsets and bigger views of Angkor wat temple.{" "}
            </p>
            <p>&nbsp;</p>
            <p className="moreBtn">
              <Link to="bakhengtemple">Read more</Link>
            </p>
            <p className="clear">&nbsp;</p>
            <p>&nbsp;</p>
            <p>
              <span className="floatL marRight20 shadow">
                <img src={Image} width={560} />
              </span>
            </p>
            <h4>Baphuon Temple</h4>
            <p>
              <strong>Time:</strong> 07:30 - 17:30
            </p>
            <p>
              <strong>Place:</strong> Siem Reap, Cambodia
            </p>
            <p>
              The most historical site present in Located in Angkor Thom,
              northwest of the Bayon is the Baphuon temple. It is a three-tier
              mountain temple built in the mid of 11th century as a state temple
              of Udayadityavarman II dedicated to the Hindu God Shiva. It is a
              typical example of the Baphuon style. The look of this temple
              impressed the Emperor Chengzong of Yuan, china’s 13th-century
              envoy Chou Ta- Kuan during his visit from 1296 to 1297.
            </p>
            <p>&nbsp;</p>
            <p className="moreBtn">
              <Link to="baphuontemple">Read more</Link>
            </p>
            <p className="clear">&nbsp;</p>
            <p>&nbsp;</p>
            <p>
              <span className="floatR marLeft20 shadow">
                <img src={Image} width={560} />
              </span>
            </p>
            <h4>Banteay Srei Temple</h4>
            <p>
              <strong>Time:</strong> 05:00 - 17:30
            </p>
            <p>
              <strong>Place:</strong> Siem Reap
            </p>
            <p>
              The most historical site present in This temple is a 10th-century
              Cambodian temple located in the Angkor region. It is dedicated to
              Lord Shiva, near the hill of Phnom Dei. This hill is located in
              the northeast of the main group of temples, which belonged to the
              medieval capitals of Yasodharapura and Angkor Thom. The buildings
              of this temple are miniature in scale when measured by the
              standards of Angkorian construction.
            </p>
            <p>&nbsp;</p>
            <p className="moreBtn">
              <Link to="banteaysritemple">Read more</Link>
            </p>
            <p className="clear">&nbsp;</p>
            <p>&nbsp;</p>
            <p>
              <span className="floatL marRight20 shadow">
                <img src={Image} width={560} />
              </span>
            </p>
            <h4>Preah Khan Temple</h4>
            <p>
              <strong>Time:</strong> 07:30 - 17:30
            </p>
            <p>
              <strong>Place:</strong> Siem Reap, Cambodia
            </p>
            <p>
              The most historical site present in This temple was built, in
              Angkor Cambodia for king Jayavarman VII, in the 12th century to
              honor his father. It had almost 100000 officials and servants and
              was considered the center of a substantial organization. Northeast
              of the Angkor Thom and west of the Jayatatakabaray are the
              locations where Preah Khan is located.{" "}
            </p>
            <p>&nbsp;</p>
            <p className="moreBtn">
              <Link to="preahkhantemple">Read more</Link>
            </p>
            <p className="clear">&nbsp;</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TempleData;
