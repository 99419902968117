import React from "react";

const TicketPrices = () => {
  return (
    <>
      <div className="centerCol">
        {/* / welcome box \ */}
        <div className="contentBox">
          <h3>Ticket Prices</h3>
          <div className="contant">
            <h4>Fee Structure</h4>
            <h4>Bifurcations of the fees are as follows:-</h4>
           <table className="tables_data">
            <tr>
              <td>1 Days Angkor Wat Temple Fee</td>
              <td>USD 45 (37 Ticket Prices + USD 4 International Transaction Fee + USD 4 Processing Fees = 45)</td>
            </tr>
            <tr>
              <td>3 Days Angkor Wat Temple Fee</td>
              <td>USD 72 (62 Ticket Prices + USD 4 International Transaction Fee +USD 6 Processing Fees = 72)</td>
            </tr>
            <tr>
              <td>5 Days Angkor Wat Temple Fee</td>
              <td>USD 82 (82 Ticket Prices + USD 4 International Transaction Fee + USD 6 Processing Fees = 82)</td>
            </tr>
            <tr>
              <td>1 Day Koh Ker Temple Fee</td>
              <td>USD 20 (15 Ticket Prices + USD 2 International Transaction Fee + USD 3 Processing Fees = 20)</td>
            </tr>
           </table>
          </div>
        </div>
      </div>
    </>
  );
};


export default TicketPrices;
