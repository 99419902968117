import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Outlet } from "react-router-dom";

const Admin = () => {
  const logout = () => {
    localStorage.removeItem("adminAuth");
    window.location.reload();
  };

  return (
    <div className="admin-layout">
      {localStorage.getItem("adminAuth") && (
        <Navbar bg="light" expand="lg">
          <Container>
            <Navbar.Brand href="/client-admin">
              <img src="/images/logo.png" className="w-50" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link onClick={() => window.close()}>Browse List</Nav.Link>
              </Nav>
              <Nav>
                <Nav.Link onClick={logout}>Logout</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      )}
      <div className="admin-main">
        <Outlet />
      </div>
    </div>
  );
};

export default Admin;
